<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
        <section class="item-types">
          <h2 class="titlePage_subtitle titlePage">PET DOOR</h2>

          <div class="row">

            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-12">
                  <p class="text">Size</p>
                </div>
                <div class="col-12"  v-for="item in settings.groups.size.choices" :key="item.id">
                  <label class="form__label form__label_row mb-2">
                    <input type="radio" hidden="hidden"
                           class="form__radio"
                           name="size"
                           @change="selectSizeOption(item)"
                           v-bind:checked="item.default"
                    >
                    <span class="form__radio_icon"></span>
                    <span class="form__labelTitle">{{item.name}}</span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <p class="text">Flaps</p>
                </div>
                <div class="col-12">
                  <label class="form__label form__label_row mb-2" v-for="item in settings.groups.flap.choices" :key="item.id">
                    <input type="radio" hidden="hidden"
                           class="form__radio"
                           name="flap"
                           v-bind:checked="item.default"
                           :disabled="disabledRadios"
                           @change="selectFlapsOption(item)"
                    >
                    <span class="form__radio_icon"></span>
                    <span class="form__labelTitle">{{item.name}}</span>
                  </label>
                </div>

              </div>

            </div>
            <div class="col-12 col-md-4">

              <div class="row align-middle" v-if="settings.groups.size.colour">
                <div class="col-12">
                  <p class="text">Colour</p>
                </div>

                <div class="col-6">
                  <p class="text_default">{{settings.groups.size.colour.name}}</p>
                </div>
                <div class="col-6"
                     @click="openColourSelection()"
                >
                  <div class="colours colour_box colours_small">
                  <img class="box__img" :src="settings.groups.size.colour.image" alt="color.colour_name" v-if="settings.groups.size.colour.image">
                  <p v-else-if="settings.groups.size.colour.hexcode">
                    <svg-icon name="palette" class="large_icon" :style="{color: settings.groups.size.colour.hexcode}"/>
                  </p>
                  <p v-if="!settings.groups.size.colour.image && !settings.groups.size.colour.hexcode">
                    <svg-icon name="palette" class="large_icon"/>
                  </p>
                </div>
                </div>
              </div>

            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-12">
                  <p class="text">Position</p>
                </div>
                <div class="col-12">
                  <img :src="position_image" class="pos__img" alt="Pet Door Position Image">
                </div>
                <div class="col-12" v-for="item in settings.groups.position.choices" :key="item.id">
                  <label class="form__label form__label_row">
                    <input type="radio" hidden="hidden"
                           class="form__radio"
                           name="position"
                           @change="selectPositionOption(item)"
                           :disabled="disabledRadios"
                           v-bind:checked="item.default"

                    >
<!--                    v-validate="'required'"-->
                    <span class="form__radio_icon"></span>
                    <span class="form__labelTitle">{{item.name}}</span>
                  </label>
                </div>
<!--                <div class="col-12">
                  <span class="form__error">{{ errors.first('position') }}</span>
                </div>-->
              </div>

            </div>
            <div class="col-12 text_center">
              <hr>
              <p class="option_note">Please note: Looking from the outside</p>
            </div>
          </div>

          <transition name="fade" mode="out-in">
            <select-colors v-show="selectColours"
                           @onSelect="setColour"></select-colors>
          </transition>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="button"
                      class="btn btn_transparent"
                      @click.prevent="savePet"
              ><svg-icon name="solid/check" /> Done</button>
            </portal>
          </template>
          <template v-else>
            <button type="button"
                    class="btn btn_default"
                    @click.prevent="savePet"
            ><svg-icon name="solid/check" /> Done</button>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import {defineAsyncComponent} from "vue";
  const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

  export default {
    name: "PetDoor",
    data() {
      return {
        disabledRadios: false,
        original: {},
          position_image: ''
      }
    },

    props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],

      components: {
          SelectColors,
      },

    methods: {
      ...mapMutations('options', ['setType']),

        openColourSelection(){
            if (this.settings.groups.size.colour_group)
                this.$store.commit('optionColours/colourGroup', this.settings.groups.size.colour_group);

            this.$store.commit('optionColours/openModal', true);
            this.$store.commit('optionColours/getColors');
        },


      savePet() {
         /* this.$validator.validate().then(valid => {*/
              this.$store.commit('orderPosition/optionNext', 'PET');
      /*    })*/
      },

      closePopup() {
        this.$store.commit('options/closeAvailable');
      },

      setSelected(keyName, data) {
          this.$store.commit('orderPosition/setOption', { option: 'PET', selection: this.settings});
          this.disabledRadios = this.noDoorsSelected();
          _.forEach(this.settings.groups.position.choices, (i) => {
             if (i.default == true)
                 this.position_image = i.image;
          });

      },

      initializeData() {
          _.forEach(this.settings.groups.position.choices, (i) => {
              if (i.default == true)
                  this.position_image = i.image;
          });
      },

      selectSizeOption(item) {
          _.forEach(this.settings.groups.size.choices, (i, k) => {
              i.default = item.name == i.name;
              if (i.default == true)
              {
                  this.settings.groups.size.colour = i.colour;
                  this.settings.groups.size.colour_group = i.colour_group;
              }
          });
          this.setSelected();
      },

      selectFlapsOption(item) {
        if (this.disabledRadios) {
          return false;
        }
          _.forEach(this.settings.groups.flap.choices, (i, k) => {
              i.default = item.name == i.name;
          });
          this.setSelected();
      },

      selectPositionOption(item) {
        if (this.disabledRadios) {
          return false;
        }

          _.forEach(this.settings.groups.position.choices, (i, k) => {
              i.default = item.name == i.name;
          });

          this.setSelected();
          this.position_image = item.image;
      },

      noDoorsSelected() {
        return this.size == 'No Pet Door';
      },

      setColour(){
          let c = this.$store.getters['optionColours/selectedColour'];
          this.settings.groups.size.colour = c;

          if (this.settings.groups.size.colour) {
              this.$store.commit('options/updateColour', {option: 'PET', colour: this.settings.groups.size.colour} );
          }
      },

    },

    computed: {
      ...mapState('options', ['modalView', 'type']),
      ...mapGetters({
          order: 'orderPosition/orderPosition',
          selectColours: 'optionColours/openModal',
      }),

      settings() {
          return this.$store.getters['orderPosition/getCurrentOptionSettings'];
      },
    },

    created() {
      this.initializeData();
    },
  }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .form {
    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px;
      margin: 20px 0;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 20px 0;
      max-width: none;
      width: 100%;
    }
    &__radio {
      &:disabled {
        ~ .form__labelTitle {
          cursor: default;
          opacity: 0.5;
          &:hover {
            color: $navy;
          }
        }
      }
    }
  }

  .modal__close {
    color: $green;
  }

  .pos__img{
    max-width: 150px;
  }

</style>
